import type { FormHeadingTranslationObj } from "../../translation-keys/form-heading-text-keys.js";

const GenericHeading = "Download this asset by filling out this form.";

const FormHeading: FormHeadingTranslationObj = {
  "document-Heading": GenericHeading,
  "document-landing-page-Heading": GenericHeading,
  "post-event-assets-Heading": "",
  "ppc-Heading": GenericHeading,
  "case-study-Heading": GenericHeading,
  "video-gated-Heading": "",
  "video-Heading": GenericHeading,
  "video-landing-page-Heading": GenericHeading,
  "events-registration-Heading": "Register by filling out the form below.",
  "resource-forms-Heading": "Download the supporting resources by filling out this form.",
  "contact-Heading": "Tell us a little about yourself so we can answer your software, quality and compliance questions.",
  "qx-roi-Heading": "Schedule Your ROI Assessment Today",
  "mx-roi-Heading": "Schedule Your ROI Assessment Today"
};

export default FormHeading;